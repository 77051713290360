//adds 'revealed' class when scrolled into view. 'offset' - from window bottom
$.fn.reveal = function(offset) {
    var $reveals = $(this);
    var offset = offset || 0;
    $reveals.each(function() {
      var $this = $(this);
      $(window).on("load", function() {
       handleRevealedClass();
      });
      $(window).on("scroll", function() {
        handleRevealedClass();
      });
    function handleRevealedClass(){
        var windowTop = $(window).scrollTop();
        var windowBottom = windowTop + $(window).height();
        windowBottom - offset > $this.offset().top && $this.addClass("revealed");
    }
    
    });
  };
  
  //surround each character with 'span' and inline incremented style 'transition-delay'
  $.fn.addCharDelay = function(delay) {
    var $reveals = $(this);
    $reveals.each(function() {
      var $reveal = $(this);
      var revealString = $reveal.text();
      var $result = $("<div></div>");
      var delay = delay || 10;
  
      for (var i = 0; i < revealString.length; i++) {
        var revealChar = revealString[i];
        if (charIsSpace(revealChar)) {
          revealChar = "&nbsp;";
        }
        $result = $result.append(
          "<span style='transition-delay:" +
            i * delay +
            "ms;' class='initial'>" +
            revealChar +
            "</span>"
        );
      }
      $reveal.html($result);
    });
    function charIsSpace(revealChar) {
      return revealChar == " ";
    }
  };
  
  //Call functions
  $(".reveal").reveal(0);
  $(".text-swing").addCharDelay(20);
  
  