$(window).on("load", function () { // Formstack forms aren't available on the initial DOM; they get loaded in by a script.
    // Select validation error message on failed form submission.
    $("form input[type=submit]").click(function () {
        waitForValidation($(this).closest("form"));
    });
    // Select validation error message on failed field navigation.
    $("form input, form textarea").blur(function () {
        //updateValidationMessage($(this).parent(".fsValidationError"));
    });

    //reCAPTCHA Validation
    $("form").submit(function (evt) {
        if (grecaptcha.getResponse() === "") {
            alert("Please check I'm not a robot !");
            evt.preventDefault();
        }
    });
});

function waitForValidation($form) {
    if ($form.find(".fsValidationError").length) {
        $(window).scrollLeft(0); // Edge scrolls so that the form is on the left edge of the page when invalid.  This undoes that.
        updateValidationMessages($form);
    } else {
        setTimeout(waitForValidation, 100, $form);
    }
}

function updateValidationMessages($form) {
    $form.find(".fsValidationError").each(function () {
        updateValidationMessage($(this));
    });
}

function updateValidationMessage($field) {
    $field.removeClass("empty").removeClass("misformatted").removeClass("invalid");
    var $requiredChildren = $field.children("input.fsRequired, textarea.fsRequired");
    var $formattedChildren = $field.children('input[class^="fsFormat"], input[class*=" fsFormat"], textarea[class^="fsFormat"], textarea[class*=" fsFormat"]');
    if ($requiredChildren.length && $requiredChildren.val() === "") {
        $field.addClass("empty");
    } else if ($formattedChildren.length && $formattedChildren.val() !== "") {
        $field.addClass("misformatted");
    } else { // We have failed to determine why the field is invalid, so just display a generic error message.
        $field.addClass("invalid");
    }
}