
$(document).ready(function () {
    $(".toggle-theme").on('click', function () {
        $("body").toggleClass("dark");
    });
});


function copyToClipboard() {
    var copyText = document.getElementById("myInput");
    copyText.select();
    document.execCommand("Copy");
    alert("Copied the text: " + copyText.value);
}