$(document).ready(function () {
    var $sticky = $(".sticky-cta");

    setLinks();

    function setLinks() {
        $sticky.find('a[href="javascript:void(0)"]').each(function () {
            var href = $(this).find('span[href]').attr('href');
            if (href) {
                this.href = href;
            }
        });
    }
})