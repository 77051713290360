$(document).ready(function () {
    var speed = 500;
    $(".accordion").find(".trigger").on('click', function () {
        var $content = $(this).closest('.accordion').find(".accordion-content");
        $('.accordion').removeClass('open').find(".accordion-content").slideUp(speed);

        (accordionIsOpen($content)) ?
        $content.slideUp(speed).closest('.accordion').removeClass('open') :
        $content.slideDown(speed).closest('.accordion').addClass('open');
    });

    function accordionIsOpen($content) {
        return $content.css('display') == 'block';
    }
})