$(document).ready(function () {
    // modal wireup
    var lightboxNumber = 0;
    $('.modal-container').each(function (idx, item) {
        var id = 'lightbox-' + lightboxNumber++;
        var modal = $('.modal.fade', item);
        $('.modal-trigger', item).attr('data-trigger', id).find('.trigger .sf_colsIn *').click(function () {
            // Only execute the following on frontend pages.
            if ($('.sfPageEditor').length === 0) {
                modal.modal();
            }
        });
        modal.attr('id', id).click(function () {
            $(this).modal('hide');
        }).find('.modal-content .content .sf_colsIn').click(function (e) {
            e.stopPropagation();
        });
    });
});