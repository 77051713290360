$(window).on('load', function () { // The form isn't available on the initial DOM; it gets loaded in by a script.
    // Change each label into a placeholder.
    $('.lump-sum-form input + label').each(function () {
        var placeholderText = $(this).contents().filter(function () {
            return this.nodeType == Node.TEXT_NODE; // This filters out any non-text nodes, such as asterisks in hidden spans.
        }).text();
        $(this).prev('input').attr('title', placeholderText).attr('placeholder', placeholderText);
    });
    // Mark labels of radio buttons as checked when their radio buttons are checked.
    $('.lump-sum-form input[type=radio]').click(function () {
        $(this).parent('label').addClass('checked').siblings('label').removeClass('checked');
    });
    // Validate zip code fields.
    $('.lump-sum-form input[type=text][title*="Zip"]').addClass('fsFormatZipUS'); // Formstack provides format validation for US, CA, UK, and AU postal codes.
});