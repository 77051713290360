$(document).ready(function () {
    const lazyBackgrounds = Array.from(document.querySelectorAll(".lazy-bg"));
    const lazyImages = Array.from(document.querySelectorAll(".lazy-img"));
    const lazyFrames = Array.from(document.querySelectorAll("iframe[data-src]"));
    const loadedClass = "inView";
  
    console.log(lazyBackgrounds);
    const lazyElementObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !entry.target.classList.contains(loadedClass)
        ) {
          const element = entry.target;
          const src = element.dataset.src;
  
          switch (element.nodeName) {
            case "IMG":
              element.src = src;
              break;
            case "IFRAME":
              element.src = src;
              break;
            default:
              element.style.backgroundImage = `url(${src})`;
              break;
          }
  
          element.classList.add(loadedClass);
          lazyElementObserver.unobserve(element);
        }
      });
    });
  
    window.addEventListener("DOMContentLoaded", () => {
      [...lazyImages, ...lazyBackgrounds, ...lazyFrames].forEach((el) =>
        lazyElementObserver.observe(el)
      );
    });
  
    window.addEventListener("scroll", () => {
      [...lazyImages, ...lazyBackgrounds, ...lazyFrames].forEach((el) =>
        lazyElementObserver.observe(el)
      );
    });
  });
  